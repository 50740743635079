@mixin filter-select-responsive {
  padding-right: 20px;
  max-width: 160px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (min-width: 1300px) {
    min-width: 110px;
  }
  @media screen and (min-width: 1500px) {
    min-width: 120px;
  }
}
.select-group {
  position: relative;
  & + .select-group {
    margin-left: 24px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  // &::after {
  //   font-family: 'insight-icons' !important;
  //   content: '\e906';
  //   font-size: 14px;
  //   display: block;
  //   position: absolute;
  //   right: 0px;
  //   bottom: 6px;
  //   color: $insight-light-gray;
  // }
  label {
    font-size: 10px;
    line-height: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $insight-light-gray;
    display: block;
    margin-bottom: 0px;
  }
  select {
    appearance: none;
    outline: none;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $insight-gray;
    background: none;
    border: none;
    border-bottom: solid 1px $insight-gray;
    padding-bottom: 8px;
    border-radius: 0px;
    z-index: 1;
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
    @include filter-select-responsive();
  }
  .dropdown-btn {
    appearance: none;
    outline: none;
    font-size: 12px;
    line-height: 18px;
    min-height: 20px;
    font-weight: 500;
    color: $insight-primary;
    background: none;
    border: none;
    border-bottom: solid 1px $insight-gray;
    padding: 0px;
    padding-bottom: 8px;
    border-radius: 0px;
    z-index: 1;
    text-align: left;
    @include filter-select-responsive();
    &:focus {
      color: $insight-secondary;
      border-bottom-color: $insight-secondary;
    }
    &.dropdown-open {
      color: $insight-secondary;
      border-bottom-color: $insight-secondary;
    }
    &.option-is-selected {
      color: $insight-secondary-dark;
      border-bottom-color: $insight-secondary-dark;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
    &.error {
      border-color: $insight-orange;
    }
    &::after {
      font-family: 'insight-icons' !important;
      content: '\e906';
      font-size: 14px;
      display: block;
      position: absolute;
      right: 0px;
      bottom: 6px;
      color: $insight-light-gray;
      transition: color 0.2s ease-in-out;
    }
    &:hover {
      &::after {
        color: $insight-gray;
      }
    }
  }
  &.date-picker {
    .input-group {
      margin-bottom: 0px;
      position: relative;
      .form-control {
        outline: none;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: $insight-gray;
        background: none;
        border: none !important;
        border-bottom: solid 1px $insight-gray !important;
        box-shadow: none !important;
        padding: 0px;
        padding-bottom: 8px;
        border-radius: 0px;
        z-index: 1;
        height: 32px;
        &::placeholder {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          color: $insight-gray;
          opacity: 0.4;
        }
        &:disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
      .input-group-append {
        position: absolute;
        right: 20px;
        top: 2px;
        .input-group-text {
          border: none;
          background: none;
          padding: 0px;
        }
      }
    }
  }
}

.input-group {
  position: relative;
  margin-bottom: 24px;
  &.inline {
    margin-bottom: 0px;
    & + .input-group {
      margin-left: 24px;
    }
  }
  label {
    font-size: 10px;
    line-height: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $insight-light-gray;
    display: block;
    margin-bottom: 0px;
  }
  .text-input {
    outline: none;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $insight-gray;
    background: none;
    border: none;
    border-bottom: solid 1px $insight-gray;
    padding-bottom: 8px;
    border-radius: 0px;
    z-index: 1;
    @include filter-select-responsive();
    &::placeholder {
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: $insight-gray;
      opacity: 0.4;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.select-inline {
  position: relative;
  display: inline-flex;
  align-items: center;

  &::after {
    font-family: 'insight-icons' !important;
    content: '\e906';
    font-size: 14px;
    display: block;
    right: 0px;
    top: 0px;
    color: $insight-light-gray;
    margin-left: 5px;
    position: absolute;
  }
  select {
    appearance: none;
    outline: none;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $insight-gray;
    background: none;
    border: none;
    border-radius: 0px;
    padding-right: 14px;
    z-index: 1;
    min-width: 36px;
  }
}

.inline-form {
  display: flex;
  align-items: center;
  .insight-select-group {
    // flex: 1 1 120px;
    & + .insight-select-group {
      margin-left: 10px;
    }
  }
}

.btn {
  @include button-variant(
    #ffffff,
    $insight-light-gray,
    rgba($insight-light-gray, 0.2)
  );
  height: 52px;
  color: $insight-gray;
  border-radius: 100px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 0px rgba($insight-light-gray, 0.4);
  font-size: 14px;
  font-weight: 600;
  padding: 0px 24px;
  text-transform: uppercase;
  i {
    color: $insight-light-gray;
    margin-right: 10px;
    transition: color 0.15s ease-in-out;
  }
  &-sm {
    height: 32px;
    padding: 0px 16px;
    font-size: 14px;
    min-height: 32px;
    line-height: 2;
    * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &-icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 100%;
    padding: 0px;
    &:before {
      flex: 1;
      color: $insight-light-gray;
      font-size: 24px;
      font-weight: 400;
    }
  }
  &:disabled,
  &.disabled {
    opacity: 0.25;
    cursor: not-allowed;
    pointer-events: none;
    color: $insight-gray;
    border-color: $insight-gray;
    pointer-events: none;
    &:hover {
      color: $insight-gray;
    }
  }
  &:hover {
    background-color: #ffffff;
    color: $insight-primary;
    box-shadow: 0px 6px 9px rgba($color: $insight-primary, $alpha: 0.12);
    i {
      color: $insight-gray;
    }
  }
  &:focus {
    &:not(.btn-primary),
    &:not(.custom-focus),
    &:not(.bg-transparent) {
      outline: none !important;
      background-color: #ffffff !important;
      border-color: $insight-secondary;
      color: $insight-gray !important;
    }
    &:not(.shadow-none) {
      box-shadow: 0px 2px 0px rgba($insight-light-gray, 0.4) !important;
    }
  }
  &:focus-visible {
    border-color: $insight-gray !important;
  }
  &.active {
    color: #ffffff !important;
    border-color: $insight-secondary !important;
    background-color: $insight-secondary !important;
    i {
      color: #ffffff !important;
    }
  }
  &-warning {
    @include button-variant($insight-orange, $insight-orange, $insight-orange);
    color: white;
    &:disabled,
    &.disabled {
      color: #ffffff;
    }
    &:focus {
      color: white;
      border-color: $insight-yellow;
    }
    &:hover {
      color: white;
      border-color: $insight-orange;
    }
  }
  &-light {
    @include button-variant(
      $insight-light-gray,
      $insight-light-gray,
      $insight-light-gray
    );
    color: white;
    &:disabled,
    &.disabled {
      color: #ffffff;
    }
  }
  &-link {
    color: $insight-primary;
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
    display: block;
    text-transform: none;
    &:hover {
      text-decoration: none;
      color: darken($color: $insight-primary, $amount: 20);
    }
    &:active {
      box-shadow: none !important;
      text-decoration: none;
      color: darken($color: $insight-primary, $amount: 20) !important;
    }
    &:focus-visible {
      box-shadow: none !important;
      color: darken($color: $insight-primary, $amount: 20) !important;
      text-decoration: none;
      outline: 1px solid $insight-primary !important;
    }
    &:focus {
      box-shadow: none !important;
      color: darken($color: $insight-primary, $amount: 20) !important;
      text-decoration: none;
    }
  }
  &-primary {
    @include button-variant(
      $insight-secondary,
      $insight-secondary,
      $insight-secondary
    );
    color: #ffffff;
    i {
      color: #ffffff;
    }
    &:hover {
      color: white;
      border-color: $insight-secondary;
    }
    &:focus {
      color: white;
      border-color: $insight-yellow;
    }
    &:disabled,
    &.disabled {
      color: #ffffff;
    }
  }
  &-close {
    border: none !important;
    background-color: transparent !important;
    &:focus {
      border: none !important;
      background-color: transparent !important;
      i {
        color: $insight-primary;
      }
    }
    &:hover {
      border: none !important;
      background-color: transparent !important;
      i {
        color: $insight-primary;
      }
    }
    i {
      color: $insight-gray;
      font-size: 12px;
    }
  }
  &.cancel-btn {
    color: $insight-orange;
  }
  &.side-bar-button {
    border-color: $insight-mild-yellow;
    background-color: $insight-mild-yellow;
    color: $insight-primary !important;
    border-radius: 4px;
    width: calc(100% - 26px);
    text-align: center;
    padding: 0;
    font-weight: 700 !important;
    font-size: 10px !important;
    line-height: 15px !important;
    box-shadow: 0px 3px 7px rgba($color: #000000, $alpha: 0.17);
    &:hover {
      background-color: darken($color: $insight-mild-yellow, $amount: 5);
      border-color: darken($color: $insight-mild-yellow, $amount: 5);
    }
  }
  &.danger-btn {
    color: $insight-orange;
    border-color: $insight-orange;
  }
}

.btn-toggle-group {
  display: flex;
  align-items: center;
  .btn {
    &:disabled {
      opacity: 1;
      border-color: $insight-light-gray !important;
    }
    &:not(.active) {
      span {
        display: none;
      }
    }
    &:first-of-type {
      margin-right: -35px;
      &:not(.active) {
        padding-right: 35px;
        border-radius: 50px 0px 0px 50px;
      }
    }
    &:last-of-type {
      flex-direction: row-reverse;
      &:not(.active) {
        padding-left: 35px;
        border-radius: 0px 50px 50px 0px;
      }
      i {
        margin-right: 0px;
      }
      span {
        margin-right: 10px;
      }
    }
    &.active {
      z-index: 1;
      pointer-events: none;
    }
  }
}

.insight-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 0px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    cursor: pointer;
  }
  i {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: #ffffff;
    border: 1px solid $insight-light-gray;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba($insight-light-gray, 0.4);
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    color: #ffffff;
    &::before {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
  }
  input:checked ~ i {
    background-color: $insight-secondary;
    border-color: $insight-secondary;
    &::before {
      opacity: 1;
    }
  }
  &.disabled-checkbox {
    pointer-events: none;
    cursor: default;
    input:checked ~ i {
      background-color: $insight-light-gray;
      border-color: $insight-light-gray;
      &::before {
        opacity: 1;
      }
    }
  }
  input:focus-visible ~ i {
    // colors taken from bootstrap
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-color: #80bdff;
  }
}

.insight-radio {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  margin: 0px;
  i {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: #ffffff;
    border: 1px solid $insight-light-gray;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba($insight-light-gray, 0.4);
    border-radius: 100%;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    color: #ffffff;
    cursor: pointer;
    &::before {
      transition: background-color 0.2s ease-in-out;
      content: '';
      width: 4px;
      height: 4px;
      background: $insight-light-gray;
      border-radius: 100%;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
  }
  input:checked ~ i,
  input.active-radio ~ i {
    background-color: $insight-secondary;
    border-color: $insight-secondary;
    &::before {
      background: #ffffff;
    }
  }
  input:focus-visible ~ i {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-color: #80bdff;
  }
  input:disabled ~ i {
    cursor: default;
    pointer-events: none;
  }
  &.disabled-radio {
    pointer-events: none;
    cursor: default;
    input:checked ~ i {
      background-color: $insight-light-gray;
      border-color: $insight-light-gray;
      &::before {
        opacity: 1;
      }
    }
  }
}

.radio-button:focus-visible ~ i {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-color: #80bdff;
}

.insight-radio-group,
.insight-checkbox-group {
  margin: 0px !important;
  padding: 20px 0px;
  &:hover {
    label {
      color: $insight-primary;
    }
  }
  label {
    color: $insight-gray;
    display: flex !important;
    align-items: center;
    margin: 0px !important;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    text-transform: none;

    .insight-radio,
    .insight-checkbox {
      margin-right: 24px;
    }
    &.checked-label {
      color: $insight-primary;
    }
  }
  .surveys-select-label {
    font-size: 12px !important;
    line-height: 18px !important;
    cursor: pointer !important;
    &.disabled-label {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }

  &:first-of-type {
    padding-top: 8px;
  }
  &:last-of-type {
    padding-bottom: 8px;
  }
  & + #{&} {
    border-top: solid 1px $insight-light-gray;
  }
}

.form-group {
  &.insight-radio-group,
  &.insight-checkbox-group {
    &:hover {
      label {
        color: $insight-primary;
      }
    }
    label {
      color: $insight-gray;
      display: flex !important;
      align-items: center;
      margin: 0px !important;
      font-size: 14px;
      line-height: 21px;
      text-transform: none;
      font-weight: 500;
      .insight-radio,
      .insight-checkbox {
        margin-right: 24px;
      }
      &.checked-label {
        color: $insight-primary;
      }
    }
  }
}
.search-text-box {
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  background: none;
  border: none;
  border-bottom: solid 2px $insight-light-gray;
  padding: 0px 24px 14px 24px;
  width: 100%;
  max-width: 465px;
  &::placeholder {
    color: $insight-light-gray;
  }
}

.form-control {
  box-sizing: border-box;
  border: 1px solid $insight-bg-yellow;
  background-color: $insight-bg-yellow;
  border-radius: 4px;
  height: 46px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: $insight-primary;
  &.error {
    border-color: $insight-orange;
  }
  &:hover {
    border-color: $insight-yellow;
  }
  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }
  &:read-only {
    border: 1px solid $insight-bg-yellow;
    background-color: $insight-bg-yellow;
    &:hover {
      border-color: $insight-bg-yellow;
    }
  }
  &:focus {
    border-color: $insight-secondary;
    color: $insight-gray;
    box-shadow: none;
  }
  &::placeholder {
    color: $insight-light-gray;
    opacity: 0.7;
    text-transform: uppercase;
  }
}

.size-down {
  .insight-select, .insight-select-projects {
    &__control {
      height: 34px;
      min-height: 34px;
      max-width: 250px;
    }
    &__placeholder {
      line-height: 2;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__indicators {
      height: 34px;
    }
  }
  &.extend-width {
    .insight-select {
      &__control {
        max-width: 100%;
      }
    }
  }
}

.new-styles {
  .insight-select {
    &__option {
      word-break: break-all;
      white-space: break-spaces;
      &--is-focused {
        background-color: rgba(
          $color: $insight-light-gray,
          $alpha: 0.15
        ) !important;
      }
      &--is-selected {
        background-color: rgba(
          $color: $insight-light-gray,
          $alpha: 0.15
        ) !important;
      }
    }
  }
}

.insight-select {
  &__control {
    border: 1px solid $insight-light-gray !important;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba($insight-light-gray, 0.4) !important;
    border-radius: 4px;
    height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px !important;
    &:hover {
      border-color: $insight-secondary !important;
    }
  }
  &__placeholder {
    color: $insight-gray !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
  }
  &__single-value {
    color: $insight-gray !important;
  }
  &__indicator-separator {
    display: none;
  }
  &__value-container {
    padding: 2px 16px !important;
  }
  &__menu-notice {
    color: $insight-gray !important;
    cursor: default;
    font-size: 12px;
    padding: 8px 12px;
    line-height: 18px;
    font-weight: 500;
  }
  &__option {
    word-break: break-all;
    white-space: break-spaces;
    &--is-focused {
      background-color: $insight-mild-yellow !important;
      color: $insight-gray !important;
    }
    &--is-selected {
      color: #ffffff !important;
      background-color: $insight-secondary-dark !important;
    }
  }
}

.insight-select-projects {
  &__control {
    border: 1px solid $insight-light-gray !important;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba($insight-light-gray, 0.4) !important;
    border-radius: 4px;
    height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px !important;
    &:hover {
      border-color: $insight-secondary !important;
    }
  }
  &__placeholder {
    color: $insight-gray !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
  }
  &__single-value {
    color: $insight-gray !important;
  }
  &__indicator-separator {
    display: none;
  }
  &__value-container {
    padding: 2px 16px !important;
  }
  &__menu-notice {
    color: $insight-gray !important;
    cursor: default;
    font-size: 12px;
    padding: 8px 12px;
    line-height: 18px;
    font-weight: 500;
  }
  &__option {
    word-break: break-all;
    white-space: break-spaces;
    &--is-selected {
      color: #ffffff !important;
      background-color: $insight-secondary-dark !important;
    }
  }
}

.select-error {
  .insight-select__control {
    border-color: $insight-orange !important;
  }
}

.expand-height {
  .insight-select-no-border__control {
    height: auto !important;
    min-height: 46px;
  }
}

.insight-select-no-border {
  &__menu {
    z-index: 4 !important;
  }
  &__option {
    word-break: break-all;
    &--is-focused {
      color: $insight-gray !important;
      background-color: $insight-mild-yellow !important;
    }
    &--is-selected {
      color: #ffffff !important;
      background-color: $insight-secondary-dark !important;
    }
  }
}

.insight-select-no-border-projects {
  &__menu {
    z-index: 4 !important;
  }
  &__option {
    word-break: break-all;
    &--is-focused {
      color: $insight-gray !important;
      background-color: $insight-mild-yellow !important;
    }
    &--is-selected {
      color: #ffffff !important;
      background-color: $insight-secondary-dark !important;
    }
    &--is-disabled {
      color: #ffffff !important;
      background-color: $insight-secondary-dark !important;
    }
  }
}

.custom-menu-2 {
  .insight-select {
    &__menu {
      padding: 0px;
    }
    &__menu-list {
      padding: 0px;
      & > .insight-select__option {
        &:last-of-type {
          padding-bottom: 7px !important;
          .insight-checkbox-group {
            border-bottom: none !important;
          }
        }
      }
    }
    &__option {
      padding: 0px 24px;
      .insight-checkbox-group {
        border-bottom: 1px solid $insight-light-gray !important;
        padding-top: 22px !important;
        padding-bottom: 22px !important;
        margin: 0;
      }
      &--is-selected {
        label {
          color: $insight-primary;
        }
      }
    }
  }
}

.custom-menu {
  .insight-select-no-border {
    &__menu {
      width: calc(100% + 50px);
      transform: translateX(-25px);
    }
    &__value-container {
    }
    &__option {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      padding: 20px 0px 24px 32px;
      color: $insight-gray;
      border-bottom: 1px solid $insight-light-gray;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        border: 1px solid $insight-light-gray;
        box-shadow: 0px 2px 0 0 rgba($color: $insight-light-gray, $alpha: 0.4);
        background-color: white;
        left: 0px;
        top: 50%;
        margin-top: -10px;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: $insight-light-gray;
        left: 6px;
        top: 50%;
        transform: translateY(-75%);
        z-index: 2;
      }
      &--is-selected {
        color: $insight-primary;
        background-color: white;
        &::after {
          background-color: white;
        }
        &::before {
          background-color: $insight-secondary;
        }
      }
      &--is-focused {
        color: $insight-primary;
        background-color: white;
      }
    }
  }
}

.insight-select-group {
  .insight-select-no-border {
    font-size: 12px;
    line-height: 18px;
    color: $insight-gray;
    &__control {
      border: none !important;
      border-bottom: solid 1px $insight-light-gray !important;
      box-sizing: border-box;
      box-shadow: none !important;
      border-radius: 0px;
      height: 46px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding-left: 0px;
      &:hover {
        //border-color: $insight-secondary !important;
      }
      .group-item-info & {
        background: none !important;
        border-bottom: solid 1px $insight-yellow !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #324b5a;
      }
    }
    &__placeholder {
      color: $insight-gray;
    }
    &__value-container {
      padding-left: 0px;
      min-width: 100px;
    }
    &__single-value {
      color: $insight-gray;
    }
    &__indicators {
    }
    &__indicator-separator {
      display: none;
    }
    &__menu {
      font-size: 12px;
      line-height: 18px;
      z-index: 2000;
    }
  }
  .group-item-info & {
    &:after {
      display: none;
    }
  }
}

.form-group {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  label {
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: $insight-gray;
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .form-error {
    color: $insight-error !important;
    font-weight: 600 !important;
    margin-top: 4px;
    margin-bottom: 0px;
    display: inline-block;
    text-transform: none;
  }
}

.insight-from-select-container {
  .insight-from-select {
    &__control {
      box-shadow: none;
      border: 1px solid $insight-light-gray;
      &:hover {
        border: 1px solid $insight-light-gray;
      }
      &--menu-is-open {
        border: 1px solid #fcdc95 !important;
      }
    }
    &__option {
      &--is-focused {
        background-color: $insight-mild-yellow !important;
        color: $insight-gray !important;
      }
      &--is-selected {
        color: #ffffff !important;
        background-color: $insight-secondary-dark !important;
      }
    }
  }
}

.form-text,
.text-muted {
  color: $insight-gray !important;
  a {
    color: $insight-secondary;
    font-weight: 600;
  }
}

.form-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.password-field {
  position: relative;
  button {
    position: absolute;
    background-color: transparent;
    border: none;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    color: $insight-gray;
    i {
      font-size: 12px;
    }
  }
}

.marquee {
  // display: block;
  &:hover,
  &:focus {
    .marquee-inner {
      // display: inline-block;
      // animation-name: scroll-text;
      animation-duration: 5s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: backwards;
    }
  }

  /* define the animation */
  @keyframes scroll-text {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}
