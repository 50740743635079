.content-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(50, 75, 90, 0.02);
  border-radius: 4px;
  padding: 32px 40px;
  margin-bottom: 64px;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  border: solid 1px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, transform 0.3s ease-in-out;

  &-title {
    border-bottom: solid 1px $insight-light-gray;
    padding-bottom: 24px;
    @media screen and (min-width: 961px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .main-title {
      padding-right: 10px;
      @media screen and (max-width: 960px) {
        margin-bottom: 20px;
      }
    }
    .widget-title {
      display: inline-flex;
      align-items: flex-start;
      line-height: 18px;
      padding-right: 10px;
      @media screen and (max-width: 960px) {
        margin-bottom: 20px;
      }
      .help {
        height: 18px;
      }
    }
    .help {
      margin-left: 4px;
      position: relative;
      display: inline;
      .icon-help {
        position: absolute;
        top: 50%;
        margin-top: -8px;
      }
    }
    &.has-dropdowns {
      margin-top: -16px;
    }
    .icon-settings {
      margin-left: 12px;
    }
  }
  &-tabs {
    margin-top: 72px;
    display: flex;
    border-bottom: solid 1px $insight-light-gray;
    .nav-item {
      flex: 1;
      border: none;
      display: flex;
    }
    .tab {
      flex: 1;
      padding-bottom: 16px;
      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0);
      margin-bottom: -1px;
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: 24px;
      }
      &.active {
        border-bottom-color: $insight-secondary-dark;
        .value {
          color: $insight-secondary-dark;
        }
      }
      .help {
        width: 16px;
        height: 16px;
        position: relative;
        .icon-help {
          position: absolute;
          top: 4px;
        }
      }
    }
  }
  .chart-container {
    padding: 40px 0px 40px;
    &.pie-chart {
      padding: 40px 0px;
    }
    &.x-axis-label {
      padding: 40px 0px 20px;
    }
  }
  .select-group {
    select {
      border-bottom-color: $insight-light-gray;
    }
  }
  position: relative;
  .coming-soon-container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    color: $insight-secondary;
    font-weight: 700;
    text-transform: uppercase;
  }

  .insight-select-group {
    .insight-select-no-border {
      &__control {
        height: auto;
      }
    }
  }
}

.comin-soon-content {
  background: #ffffff;
  border: 1px solid $insight-secondary;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(50, 75, 90, 0.02);
  border-radius: 4px;
  padding: 35px 75px;
  text-align: center;
  color: $insight-gray;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  .title {
    color: $insight-secondary;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

.content-section {
  margin-top: 50px;
}

.facilitators-search {
  margin-top: 72px;
  margin-left: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    margin-left: 0px;
  }
  .search-textbox {
    position: relative;
    background: $insight-bg-yellow;
    height: 44px;
    i {
      position: absolute;
      color: $insight-gray;
      top: 11px;
      left: 14px;
      font-size: 20px;
    }
    .search-text {
      height: 44px;
      font-weight: 700;
      font-size: 10px;
      line-height: 20px;
      text-transform: uppercase;
      color: $insight-gray;
      padding: 0px 16px 0px 46px;
      border: none;
      background: none;
      outline: none;
      width: 100%;
      &::placeholder {
        color: $insight-light-gray;
      }
    }
  }
  .select-all {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    .checkbox-group {
      display: flex;
      color: $insight-gray;
      align-items: center;
      .insight-checkbox {
        margin-right: 16px;
      }
    }
  }
  .facilitators-list {
    flex: 1;
    overflow-y: auto;
    max-height: 100%;
    .facilitator {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      color: #738088;
      padding: 16px 0px;
      border-bottom: solid 1px $insight-light-gray;

      .truncate {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 60px;
      }

      .avatar {
        width: 32px;
        height: 32px;
        margin-right: 16px;
        position: relative;
        .status {
          position: absolute;
          z-index: 1;
          background: #68c7c2;
          border: 1px solid #ffffff;
          border-radius: 100%;
          width: 8px;
          height: 8px;
          right: 0px;
        }
        img {
          width: 100%;
          overflow: hidden;
          border-radius: 100%;
        }
      }
      .insight-checkbox {
        margin-right: 16px;
      }
    }
  }
}

.active-dashboard-widget {
  z-index: 2;
  .content-box {
    background: rgba(#ffffff, 1);
    box-shadow: 0px 8px 13px rgba(50, 75, 90, 0.12);
    border: solid 1px rgba(0, 0, 0, 0);
    cursor: grabbing;
  }
}

.active-sortable-widget-container {
  position: relative;
  &:before {
    position: absolute;
    z-index: 1;
    content: '';
    top: -20px;
    bottom: 40px;
    left: -5px;
    right: -5px;
    background: rgba(#fbf3df, 0.7);
    border: 1px dashed #dbdce1;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .active-dashboard-widget {
    .content-box {
      background: rgba(#ffffff, 0.8);
      box-shadow: 0px 4px 4px rgba(50, 75, 90, 0.08);
      cursor: grab;
      animation: none;
      border: 1px solid #68c7c2;
    }
  }
}

@keyframes jiggle {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
}
