.insight-dropdown {
  border: solid 1px $insight-light-gray;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  z-index: 300;
  min-width: 250px;
  .insight-date-picker {
    flex-direction: column;
    .react-daterange-picker, .react-date-picker {
      &__wrapper {
        border: none;
        justify-content: center;
        color: $insight-primary !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif !important;
        > * {
          margin: 0px 5px;
        }
      }
      &__inputGroup {
        min-width: 0px;
        flex-grow: 0;
        color: $insight-primary !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif !important;
        font-size: 16px;
        &__input {
          color: $insight-primary !important;
        }
      }
      &__clear-button {
        .react-daterange-picker__button__icon {
          stroke: $insight-gray !important;
          &:hover {
            stroke: $insight-primary !important;
          }
        }
      }
      &__calendar {
        position: static !important;
        height: auto !important;
        .react-calendar {
          border: none;
          margin-top: 5px;
          font-family: 'Montserrat', sans-serif !important;
          font-size: 16px;
          &__navigation {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top: 32px;
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: solid 1px $insight-light-gray;
            &__arrow {
              background: #ffffff;
              border: 1px solid $insight-light-gray;
              box-sizing: border-box;
              box-shadow: 0px 2px 0px rgba(219, 220, 225, 0.4);
              min-width: 24px;
              width: 24px;
              height: 24px;
              margin: 0px 5px;
              color: $insight-light-gray;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all 0.2s ease-in-out;
              &:hover {
                border-color: $insight-gray;
                color: $insight-gray;
              }
            }
            &__label {
              background: #ffffff !important;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: $insight-primary;
              font-family: 'Montserrat', sans-serif !important;
            }
          }
          &__viewContainer {
          }
          &__month-view {
            &__weekdays {
              &__weekday {
                abbr {
                  color: $insight-light-gray;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  text-decoration: none;
                }
              }
            }
            &__days {
              &__day {
                color: $insight-gray !important;
                &--neighboringMonth {
                  opacity: 0.2;
                }
                &--weekend {
                }
                &.react-calendar__tile--rangeStart,
                &.react-calendar__tile--rangeEnd {
                  color: #ffffff !important;
                  background: $insight-secondary;
                  border-radius: 100%;
                  flex-basis: 48px !important;
                  width: 48px !important;
                  height: 48px !important;
                }
              }
            }
          }
          &__tile {
            &--now {
              background: $insight-light-gray;
              font-weight: 600;
            }
            &--active {
              color: $insight-secondary !important;
              background: none;
            }
            
          }
        }
      }
    }
  }

  .alert {
    max-width: 200px;
  }
}

.user-dropdown-menu {
  position: relative;
  background: #ffffff;
  border: 1px solid #dbdce1;
  margin-top: 10px;
  &:after,
  &:before {
    bottom: 100%;
    left: 69%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 6px;
    margin-left: -6px;
  }
  &:before {
    border-color: rgba(219, 220, 225, 0);
    border-bottom-color: #dbdce1;
    border-width: 7px;
    margin-left: -7px;
  }
  .user-dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $insight-gray;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    &:hover {
      background-color: $insight-mild-yellow !important;
    }
  }
}

.insight-btn-dropdown {
  position: relative;
  .btn {
    border-radius: 26px !important;
  }
  &.show {
    .btn {
      border-radius: 26px 26px 0px 0px !important;
      box-shadow: none;
      border-bottom-color: #ffffff;
    }
  }
  .dropdown-menu {
    width: 100%;
    border: solid 1px #dbdce1;
    box-shadow: 0px 2px 0px rgba(219, 220, 225, 0.4);
    top: -3px !important;
    border-radius: 0px 0px 26px 26px;
    min-width: 0px;
    border-top: none;
    &:before {
      content: '';
      position: absolute;
      left: 16px;
      right: 16px;
      top: 0px;
      height: 1px;
      background-color: #dbdce1;
    }
    .dropdown-item {
      background: none;
      height: 45px;
      i {
        font-size: 20px;
        margin-right: 18px;
      }
      &:focus {
        box-shadow: none !important;
      }
      &:last-child {
        border-bottom-right-radius: 26px !important;
        border-bottom-left-radius: 26px !important;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .btn {
      border-radius: 20px !important;
    }
    &.show {
      .btn {
        border-radius: 20px 20px 0px 0px !important;
      }
    }
    .dropdown-menu {
      .dropdown-item {
        padding: 0px 15px;
        border: none;
        background: none;
      }
    }
  }
}


.calendar-button {
  border: 1px solid $insight-light-gray;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba($insight-light-gray, 0.4);
  border-radius: 4px;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $insight-primary;
  background-color: white;
  width: 100%;
  text-align: left;
  padding: 0 1rem;
  position: relative;
  &::after{
    font-family: 'insight-icons' !important;
    content: '\e906';
    font-size: 14px;
    display: block;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -8px;
    color: $insight-light-gray;
    transition: transform 0.36s ease;
  }
  &.calendar-open {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    &::after{
      transform: rotate(180deg);
    }
  }
  &:focus-visible {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &.surveys-toggle {
    height: 34px;
  }
}