.chart-details {
  .value,
  .sub-title {
    display: block;
  }
  .sub-title {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;
    color: $insight-gray;
  }
  .value {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $insight-primary;
  }
  &.separator {
    padding-top: 24px;
    margin-top: 24px;
    position: relative;
    &::before {
      width: 24px;
      height: 3px;
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      background: $insight-yellow;
    }
  }
  .widget-read-more {
    border: 1px solid transparent;
    @include button-variant(
      #ffffff,
      $insight-light-gray,
      rgba($insight-light-gray, 0.2)
    );
    height: 32px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    box-shadow: 0px 2px 0px rgba(219, 220, 225, 0.4);
    color: $insight-primary;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 16px;
    text-decoration: none;
    text-transform: uppercase;
    //position: absolute;
    right: 0px;
    bottom: 0px;
    margin-top: 24px;
    i {
      color: $insight-light-gray;
      margin-right: 10px;
      transition: color 0.15s ease-in-out;
    }
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
      color: $insight-primary;
    }
  }
  &.side-by-side {
    display: flex;
    justify-content: center;
    .padded-box {
      padding-right: 20px;
      padding-left: 20px;
      .primary-value {
        color: $insight-secondary !important;
      }
      .truncate {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media screen and (min-width: 1440px) {
          max-width: 120px;
        }
        @media screen and (min-width: 1366px) and (max-width: 1440px) {
          max-width: 100px;
        }
        @media screen and (min-width: 1024px) and (max-width: 1366px) {
          max-width: 80px;
        }
      }
    }
    .vertical-separator {
      border-right: 1px solid $insight-light-gray;
      height: 100%;
      margin-left: -3px;
    }
  }
}

.chart-legend {
  padding-top: 32px;
  padding-left: 15px;
  border-top: solid 1px $insight-light-gray;
  .chart-legend-inner {
    display: table;
  }
  .item {
    //display: table-row;
    //align-items: center;

    max-width: 100%;
    position: relative;
    padding-left: 20px;
    & + .item {
      margin-top: 16px;
    }
    .item-col {
      display: table-cell;
    }
    .color {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #eeeeee;
      margin-right: 16px;
      display: inline-block;
      position: absolute;
      left: 0px;
      top: 9px;
    }
    .color-container {
      .color {
        top: 6px;
      }
    }
    .label {
      font-size: 11px;
      line-height: 18px;
      font-weight: 600;
      .name {
        color: $insight-gray;
      }
      .value {
        font-weight: 600;
        display: inline-block;
        color: $insight-primary;
      }
    }
  }
  &.inline {
    display: flex;
    align-items: center;
    padding-right: 16px !important;
    flex-wrap: wrap;
    gap: 40px;
    .item {
      & + .item {
        margin-top: 0px;
      }
    }
  }
}

.chart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .chart-legend {
    border: none;
    padding: 0px;

    .item {
      .label {
        .name {
          color: $insight-primary;
        }
      }
    }

    .truncate {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
    }
  }
  .chart-overview {
    text-decoration: none !important;
    color: $insight-secondary;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-self: flex-end;
    min-width: 185px;
    i {
      font-size: 18px;
    }
  }
}

.chart-table-container {
  background: #ffffff;
  border: 1px solid $insight-light-gray;
  box-shadow: 0px 2px 0px rgba(219, 220, 225, 0.4);
  border-radius: 4px;
  flex: 1;
  .title {
    padding: 16px 12px;
    border-bottom: 1px solid $insight-light-gray;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: $insight-dark;
  }
  .content {
    padding: 24px 12px;
  }
  .chart-table {
    width: 100%;
    border-spacing: 0 24px;
    border-collapse: separate;
    td,
    th {
      padding: 0px 5px;
    }
    th {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: $insight-gray;
    }
    td {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: $insight-dark;
      &.value {
        text-align: right;
        color: $insight-secondary;
        font-weight: 600;
      }
    }
  }
}

.text-chat-content {
  padding: 40px 0px;
}

.chart-container {
  position: relative;
  img {
    width: 100%;
  }
}

.empty-chart-container {
  padding: 20px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.pending-changes {
  padding: 20px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  text-align: center;
  i {
    font-size: 60px;
    color: $insight-light-gray;
  }
  span {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.leader-board-container {
  padding: 32px 0px 8px;
  width: 100%;
  .leader-board {
    border-collapse: separate;
    border-spacing: 0 12px;
    width: 100%;
    td {
      vertical-align: top;
      font-size: 8px;
      font-weight: 700;
    }
    .id {
      width: 20px;
    }
    .name,
    .id {
      font-size: 9px;
      color: $insight-primary;
      font-weight: 700;
      .country {
        font-size: 7px;
        color: rgba(115, 128, 136, 0.5);
        display: block;
        text-transform: uppercase;
      }
    }
    .project {
      color: $insight-gray;
    }
    .value {
      color: $insight-secondary;
      text-align: right;
      &.star-icon {
        padding-right: 15px;
        background-image: url(../../img/gold-star.svg);
        background-repeat: no-repeat;
        background-size: 13px 13px;
        background-position: right 0px top;
      }
    }
    .truncate {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 52px;
    }
  }
}

.tooltip-container {
  border: solid 1px $insight-light-gray !important;
  background: #ffffff !important;
  position: relative;
  &:after,
  &:before {
    top: 100% !important;
    left: 50% !important;
    border: solid transparent !important;
    content: ' ';
    height: 0 !important;
    width: 0 !important;
    position: absolute !important;
    pointer-events: none !important;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0) !important;
    border-top-color: #ffffff !important;
    border-width: 6px !important;
    margin-left: -6px !important;
  }
  &:before {
    border-color: rgba(219, 220, 225, 0) !important;
    border-top-color: #dbdce1 !important;
    border-width: 7px !important;
    margin-left: -7px !important;
  }

  .tool-tip-label {
    display: block;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: $insight-gray;
  }
  .tool-tip-value {
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: $insight-secondary;
  }
}

//TODO: Move these into the relevant sheet.
.label-value {
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  fill: $insight-primary;
  &.make-smaller {
    font-size: 0.6rem;
    @media screen and (min-width: 1025px) and (max-width: 1366px) {
      font-size: 0.45rem;
    }
  }
}

.gauge-value {
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  fill: $insight-primary;
}

.gauge-value {
  font-size: 14px;
}

.horizontal-bar-label {
  font-size: 11px;

  .value {
    fill: $insight-primary;
    font-weight: 600;
  }
  .label {
    fill: $insight-gray;
    font-weight: 600;
  }
}

.vertical-bar-label {
  font-size: 12px;

  .value {
    fill: $insight-primary;
    font-weight: 600;
    line-height: 20px;
  }
}

.vertical-bar-x-axis {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: $insight-primary;
  text-align: center;
}

.group-bar-x-axis {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  color: #738088;
  text-align: center;
}

.chart-tooltip-container {
  background: #ffffff;
  border: solid 1px $insight-light-gray;
  border-radius: 4px;
  color: $insight-gray;
  padding: 8px 24px;
  font-size: 11px;
  line-height: 18px;

  .inline {
    display: inline-flex;
  }
  .value {
    color: $insight-secondary;
    font-weight: 600;
  }
  .label {
    color: rgb(50, 75, 90);
    font-weight: 600;
  }
  .truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
  }
}

.no-data-message {
  background: #ffffff;
  border: 1px solid $insight-orange;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(50, 75, 90, 0.02);
  border-radius: 4px;
  padding: 35px 40px;
  text-align: center;
  color: $insight-gray;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  .title {
    color: $insight-orange;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

.backup-status-bar {
  cursor: pointer;
  &.disabled {
    pointer-events: none;
  }
}

// Nivo does not support to disable to the area of selected data sets.
.ins-custom-chart-wrapper.line-area-char {
  path[fill='#fcdc95'] {
    fill: none;
  }
  path[fill='#93CEEF'] {
    fill: none;
  }
}

.chart-table-column {
  display: flex;
}

.content-box {
  .has-dropdowns {
    .form-group {
      margin-bottom: 0 !important;
      label.chart-filter-label {
        font-size: 10px;
        line-height: 15px;
        font-weight: 700;
        color: $insight-light-gray;
        margin-bottom: 0;
      }
    }
  }
}
