.group-item {
  display: flex;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-item-content {
  flex: 1;
  padding-top: 56px;
  padding-bottom: 56px;
  margin-right: 40px;
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  @media screen and (min-width: 1420px) {
    .dashboard-widget {
      &.col-xl-6 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  }
}
.group-details-left-outer {
  display: flex;
}
.group-item-info {
  width: 250px;
  background: #f9f0db;
  min-height: calc(100vh - 95px);
  margin-right: 40px;
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  .item-info-inner {
    padding: 56px 40px;
  }
  .item-title {
    color: $insight-primary;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .item-sub-title {
    font-weight: 700;
    font-size: 8px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  .backup-tag {
    font-size: 10px;
  }
  button.input-group {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;

    &.edit-mode {
      input {
        background-color: $insight-bg-yellow;
        color: $insight-light-gray;
        height: 46px;
        line-height: 18px;
        font-weight: 700;
        font-size: 12px;
        padding: 18px 12px;
        margin-top: 8px;
        border-radius: 4px;
      }
    }
    &.edit-active {
      position: relative;
      &::after {
        font-family: 'insight-icons' !important;
        content: '\e906';
        font-size: 14px;
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -5px;
        color: $insight-gray;
        transition: transform 0.36s ease;
      }
      input {
        height: 27px;
        margin-top: 3px;
        background-color: transparent;
        padding: 0px 12px 8px 0px;
        color: $insight-secondary;
        font-weight: 500;
        border-bottom: 1px solid $insight-secondary !important;
        border-radius: 0;
      }
    }
  }
  .input-group,
  .select-group {
    margin: 0px;
    margin-bottom: 24px;
    label {
      color: $insight-gray;
    }
    .text-input,
    select {
      border-bottom-color: $insight-yellow;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $insight-primary;
      width: 100%;
      min-width: 0;
      &:read-only {
        border-bottom: none;
        appearance: none;
      }
      &:not(:read-only) {
        border: solid 1px $insight-light-gray;
        border-radius: 4px;
        height: 48px;
        padding: 5px 10px;
        font-size: 14px;
        background: #ffffff;
        color: $insight-light-gray;
        min-width: 100%;
        &:hover {
          border-color: $insight-yellow;
        }
        &:focus {
          border-color: $insight-secondary-dark;
          color: $insight-primary;
        }
      }
    }
  }
}
